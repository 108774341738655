<template>
  <div class="inner-section">
    <card>
      <template v-slot:searchHeaderTitle>
        <h4 class="card-title">{{ $t('elearning_tim.attendance') }} {{ $t('globalTrans.search') }}</h4>
      </template>
      <template v-slot:searchBody>
        <b-row>
          <b-col lg="6" sm="12">
            <b-form-group
              class="row"
              label-cols-sm="4"
              label-for="fiscal_year_id"
            >
              <template v-slot:label>
                {{$t('elearning_config.fiscal_year')}}
              </template>
              <v-select name="fiscal_year_id"
                v-model="search.fiscal_year_id"
                label="text"
                :reduce="item => item.value"
                :options= fiscalYearList
                :placeholder="$t('globalTrans.select')"
                :filter-by="myFilter"
              />
            </b-form-group>
          </b-col>
          <b-col lg="6" sm="6">
            <b-form-group
              class="row"
              label-cols-sm="4"
              label-for="circular_memo_no"
            >
            <template v-slot:label>
              {{$t('elearning_iabm.circular_memo_no')}}
            </template>
            <b-form-select
              plain
              v-model="search.circular_memo_no"
              :options="circularList"
              id="circular_memo_no"
            >
              <template v-slot:first>
                <b-form-select-option :value="0">{{ circularLoading ? 'Loading....' : $t('globalTrans.select') }}</b-form-select-option>
              </template>
            </b-form-select>
          </b-form-group>
          </b-col>
          <b-col lg="6" sm="12" v-if="$store.state.Auth.activeRoleId === 1 || $store.state.Auth.authUser.org_id === 1 || $store.state.Auth.authUser.org_id === 12">
            <b-form-group
              class="row"
              label-cols-sm="4"
              :label="$t('elearning_config.organization')"
              label-for="organization"
            >
              <v-select name="organization"
                v-model="search.org_id"
                label="text"
                :reduce="item => item.value"
                :options= orgList
                :placeholder="$t('globalTrans.select')"
                :filter-by="myFilter"
              />
            </b-form-group>
          </b-col>
          <b-col lg="6" sm="12">
            <b-form-group
              class="row"
              label-cols-sm="4"
              label-for="training_type_id"
            >
              <template v-slot:label>
                {{$t('elearning_config.training_type')}}
              </template>
              <b-form-select
                plain
                v-model="search.training_type_id"
                :options="trainingTypeList"
                id="training_type_id"
                >
                <template v-slot:first>
                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                </template>
                </b-form-select>
            </b-form-group>
          </b-col>
          <b-col lg="6" sm="12">
            <b-form-group
                class="row"
                label-cols-sm="4"
                label-for="training_title_id"
              >
                <template v-slot:label>
                  {{$t('elearning_config.training_title')}}
                </template>
                <b-form-select
                  plain
                  v-model="search.training_title_id"
                  :options="trainingTitleList"
                  id="training_title_id"
                  >
                  <template v-slot:first>
                      <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                  </template>
                  </b-form-select>
            </b-form-group>
          </b-col>
          <b-col lg="6" sm="12">
            <b-form-group
              class="row"
              label-cols-sm="4"
              label-for="batch_id"
            >
              <template v-slot:label>
                {{$t('elearning_config.batch')}}
              </template>
              <b-form-select
                plain
                v-model="search.batch_id"
                :options="batchList"
                id="batch_id"
                >
                <template v-slot:first>
                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                </template>
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col>
            <b-button size="sm" variant="primary" @click="searchData">
              <i class="ri-search-line"></i> {{ $t('globalTrans.search') }}
            </b-button>
          </b-col>
        </b-row>
      </template>
    </card>
          <body-card>
            <template v-slot:headerTitle>
                <h4 class="card-title">{{ $t('elearning_tim.attendance') }} {{ $t('globalTrans.list') }}</h4>
            </template>
            <template v-slot:headerAction>
                <!-- <b-button variant="primary" @click="pdfExportList" class="mr-2">
                    {{  $t('globalTrans.export_pdf') }}
                </b-button> -->
                <router-link to="attendance-form" :class="'btn btn-add font-weight-bold'"> <i class="fas fa-plus"> </i> {{  $t('globalTrans.add_new') }}</router-link>
            </template>
            <template v-slot:body>
                <b-overlay :show="loadingState">
                    <b-row>
                        <b-col md="12" class="table-responsive">
                            <b-table thead-class="table_head" striped bordered hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null" :emptyText="$t('globalTrans.noDataFound')" show-empty>
                                <template v-slot:cell(serial)="data">
                                {{ $n(data.index + pagination.slOffset) }}
                                </template>
                                <template v-slot:cell(type_name)="data">
                                <span class="capitalize">{{ data.item.type_name }}</span>
                                </template>
                                <template v-slot:cell(status)="data">
                                  <span class="badge badge-info" v-if="data.item.status == 1">{{$t('globalTrans.save')}}</span>
                                  <span class="badge badge-success" v-else>{{$t('globalTrans.finalSave')}}</span>
                                </template>
                                <template v-slot:cell(training_date)="data">
                                  <span class="capitalize">{{ data.item.training_date | dateFormat }}</span>
                                </template>
                                <template v-slot:cell(start_time)="data">
                                  <span class="capitalize">{{ data.item.start_time | time12FormateTraining }}</span>
                                </template>
                                <template v-slot:cell(end_time)="data">
                                  <span class="capitalize">{{ data.item.end_time | time12FormateTraining }}</span>
                                </template>
                                <template v-slot:cell(action)="data">
                                    <b-button variant="iq-bg-info" v-if="data.item.status == 1" size="sm" @click="finalSave(data.item)" ><i class="far fa-check-square m-0"></i></b-button>
                                    <!-- <b-button v-b-modal.modal-4 variant="btn-sm" size="sm" @click="edit(data.item)">
                                      <i class="fas fa-eye"></i>
                                    </b-button> -->
                                    <!-- <b-button v-b-modal.modal-4 variant="iq-bg-success mr-1" size="sm" @click="edit(data.item)" class="moc-action-btn moc-view-btn" :title="$t('globalTrans.view')"><i class="ri-eye-line"></i></b-button> -->
                                    <router-link :to="`attendance-details?circularMemoNo=${data.item.circular_memo_no}&batchId=${data.item.batch_id}`" variant="iq-bg-success mr-1" :title="$t('elearning_tim.attendance') + ' ' + $t('globalTrans.details')" size="sm" :class="'moc-action-btn moc-view-btn'"><i class="fas fa-eye"></i></router-link>
                                    <router-link v-if="data.item.status == 1" title="Edit" :to="`/training-e-learning-service/tpm/course-module-form?id=${data.item.id}`" class=" btn btn-sm"><i class="ri-ball-pen-fill"></i></router-link>
                                </template>
                            </b-table>
                            <div class="pagination-wrapper mt-3" v-if="columns.filter(item => item.show === '1').length > 0">
                              <b-pagination
                                v-model="pagination.currentPage"
                                :perPage="search.limit"
                                :total-rows="pagination.totalRows"
                                @input="searchData"
                              />
                            </div>
                        </b-col>
                    </b-row>
                </b-overlay>
            </template>
        </body-card>
        <b-modal id="modal-4" size="lg" hide-footer :title="$t('elearning_tim.attendance') + ' ' + $t('globalTrans.details')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
          <b-button variant="primary" @click="pdfExport" class="ml-4 btn-success water-test-pdf-button">
            <i class="fas fa-print"></i> {{  $t('globalTrans.print') }}
          </b-button>
          <Details :id="editItemId" :key="editItemId"  ref="details"/>
      </b-modal>
    </div>
</template>
<script>

import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import { attendanceList, circularPublicationList } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/list'
import Store from '@/store'
import ExportPdf from './export_pdf_list'
import Details from './Details'

export default {
    mixins: [ModalBaseMasterList],
    components: {
      Details
    },
    data () {
        return {
          circularLoading: false,
          sortBy: '',
          sortDirection: '',
          sortDesc: '',
          search: {
            circular_memo_no: 0,
            org_id: [],
            training_type_id: 0,
            training_title_id: 0,
            batch_id: 0,
            fiscal_year_id: [],
            limit: 30
          },
          trainingTitleList: [],
          circularList: [],
          batchList: [],
          allData: [],
          labelData: [
            { labels: 'globalTrans.sl_no', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 1, thStyle: { width: '8%' } },
            { labels: 'elearning_config.fiscal_year', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 2, thStyle: { width: '12%' } },
            { labels: 'elearning_iabm.circular_memo_no', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 3, thStyle: { width: '15%' } },
            { labels: 'elearning_config.organization', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 4, thStyle: { width: '15%' } },
            { labels: 'elearning_config.training_type', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 5, thStyle: { width: '15%' } },
            { labels: 'elearning_config.training_title', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 6, thStyle: { width: '15%' } },
            { labels: 'elearning_config.batch', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 7, thStyle: { width: '10%' } },
            { labels: 'globalTrans.action', class: 'text-left', sortable: true, stickyColumn: true, show: '1', order: 8, thStyle: { width: '10%' } }
          ],
          myFilter: (option, text, search) => {
            const temp = search.toLowerCase()
            return option.text_en.toLowerCase().indexOf(temp) > -1 ||
            option.text_bn.toLowerCase().indexOf(temp) > -1
          }
        }
    },
    computed: {
      trainingTypeList: function () {
        return this.$store.state.TrainingElearning.commonObj.trainingTypeList.filter(item => item.status === 1)
      },
      fiscalYearList: function () {
        return this.$store.state.CommonService.commonObj.fiscalYearList.filter(item => item.status === 1)
      },
      orgList: function () {
        return this.$store.state.CommonService.commonObj.componentOrgList.filter(item => item.status === 1)
      },
      trainerEvaluationList: function () {
        return this.$store.state.TrainingElearning.commonObj.trainerEvaluationList.filter(item => item.status === 1)
      },
      formTitle () {
          return (this.editItemId === 0) ? this.$t('elearning_config.trainer_evaluation') + ' ' + this.$t('globalTrans.entry') : this.$t('elearning_config.trainer_evaluation') + ' ' + this.$t('globalTrans.update')
      },
      columns () {
        const labelData = this.labelData
        const labels = labelData.map((item, index) => {
          return Object.assign(item, { label: this.$t(item.labels, this.$i18n.locale) })
        })
        let keys = []
        if (this.$i18n.locale === 'bn') {
          keys = [
            { key: 'serial' },
            { key: 'fiscal_year_bn' },
            { key: 'circular_memo_no' },
            { key: 'org_bn' },
            { key: 'training_type_bn' },
            { key: 'training_title_bn' },
            { key: 'batch_bn' },
            { key: 'action' }
          ]
        } else {
          keys = [
            { key: 'serial' },
            { key: 'fiscal_year' },
            { key: 'circular_memo_no' },
            { key: 'org' },
            { key: 'training_type' },
            { key: 'training_title' },
            { key: 'batch' },
            { key: 'action' }
          ]
        }
        return labels.map((item, index) => {
          return Object.assign(item, keys[index])
        })
      },
      pageOptions () {
        return this.$store.state.commonObj.pageOptions
      }
    },
    async created () {
      this.getCircularList()
      this.search = Object.assign({}, this.search, {
          // org_id: this.$store.state.dataFilters.orgId,
          fiscal_year_id: this.$store.state.currentFiscalYearId
      })
      this.loadData()
    },
    watch: {
      'search.fiscal_year_id': function (newValue) {
        this.getCircularList()
      },
      'search.training_type_id': function (newValue) {
        this.trainingTitleList = this.getTrainingTitleList(newValue)
      },
      'search.org_id': function (newValue) {
        this.batchList = this.getBatchList(newValue)
      }
    },
    methods: {
    getBatchList (orgId) {
      const batchList = this.$store.state.TrainingElearning.commonObj.batchList.filter(item => item.status === 1)
      if (orgId) {
        return batchList.filter(item => item.org_id === orgId)
      }
      return batchList
    },
    async getCircularList () {
      this.circularLoading = true
      const serchData = {
        fiscal_year_id: this.search.fiscal_year_id
      }
      const result = await RestApi.getData(trainingElearningServiceBaseUrl, circularPublicationList + '/' + 'iab_circular_publications', serchData)
      if (!result.success) {
      } else {
         const listObject = result.data
        const tmpList = listObject.map((obj, index) => {
          if (this.$i18n.locale === 'bn') {
            return { value: obj.circular_memo_no, text: obj.circular_memo_no }
          } else {
            return { value: obj.circular_memo_no, text: obj.circular_memo_no }
          }
        })
        this.circularList = tmpList
      }
      this.circularLoading = false
    },
      finalSave (item) {
        // this.toggleStatusCustom2(trainingElearningServiceBaseUrl, trainingScheduleFinalSave, item)
      },
      toggleStatusCustom2 (baseUrl, uri, item) {
        window.vm.$swal({
          title: window.vm.$t('globalTrans.finalSaveConfirmMsg'),
          showCancelButton: true,
          confirmButtonText: window.vm.$t('globalTrans.yes'),
          cancelButtonText: window.vm.$t('globalTrans.no'),
          focusConfirm: false
        }).then((result) => {
          if (result.isConfirmed) {
            this.changeStatusCustom(baseUrl, uri, item)
          }
        })
      },
      changeStatusCustom (baseUrl, uri, item) {
        Store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
        RestApi.deleteData(baseUrl, `${uri}/${item.id}`).then(response => {
          if (response.success) {
            window.vm.$toast.success({
              title: 'Success',
              message: 'Data Updated Successfully',
              color: '#D6E09B'
            })
          } else {
            window.vm.$toast.error({
              title: 'Error',
              message: 'Operation failed! Please, try again.'
            })
          }
          Store.dispatch('mutateCommonProperties', { loading: false, listReload: true })
        })
      },
      getTrainingTitleList (categoryId) {
        const trainingTitleList = this.$store.state.TrainingElearning.commonObj.trainingTitleList.filter(item => item.status === 1)
        if (categoryId) {
          return trainingTitleList.filter(item => item.training_type_id === categoryId)
        }
        return trainingTitleList
      },
      dataChange () {
        this.loadData()
      },
      async searchData () {
        this.loadData()
      },
      remove (item) {
        this.changeStatus(trainingElearningServiceBaseUrl, item, 'training_e_learning', 'trainerEvaluationList')
      },
      loadData () {
          const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
          this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
          RestApi.getData(trainingElearningServiceBaseUrl, attendanceList, params).then(response => {
              if (response.success) {
                  this.$store.dispatch('setList', this.getCustomDataList(response.data.data))
                  this.paginationData(response.data, this.search.limit)
                  this.allData = this.getCustomDataList(response.data.data, this.search.limit)
              }
              this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
          })
      },
      getCustomDataList (data) {
        const listData = data.map((item, index) => {
          const orgObj = this.$store.state.CommonService.commonObj.orgProfileList.find(doc => doc.value === parseInt(item.org_id))
          const orgData = {}
          if (typeof orgObj !== 'undefined') {
            orgData.org = orgObj.text_en
            orgData.org_bn = orgObj.text_bn
          } else {
            orgData.org = ''
            orgData.org_bn = ''
          }
          const batchObj = this.$store.state.TrainingElearning.commonObj.batchList.find(doc => doc.value === parseInt(item.batch_id))
          const batchData = {}
          if (typeof batchObj !== 'undefined') {
            batchData.batch = batchObj.text_en
            batchData.batch_bn = batchObj.text_bn
          } else {
            batchData.batch = ''
            batchData.batch_bn = ''
          }
          const officeObj = this.$store.state.CommonService.commonObj.officeList.find(doc => doc.value === parseInt(item.office_id))
          const officeData = {}
          if (typeof officeObj !== 'undefined') {
            officeData.office = officeObj.text_en
            officeData.office_bn = officeObj.text_bn
          } else {
            officeData.office = ''
            officeData.office_bn = ''
          }

          const fiscalYearObj = this.$store.state.CommonService.commonObj.fiscalYearList.find(doc => doc.value === parseInt(item.fiscal_year_id))
          const fiscalYearData = {}
          if (typeof fiscalYearObj !== 'undefined') {
            fiscalYearData.fiscal_year = fiscalYearObj.text_en
            fiscalYearData.fiscal_year_bn = fiscalYearObj.text_bn
          } else {
            fiscalYearData.fiscal_year = ''
            fiscalYearData.fiscal_year_bn = ''
          }
          const trainingTitleObj = this.$store.state.TrainingElearning.commonObj.trainingTitleList.find(doc => doc.value === parseInt(item.training_title_id))
          const trainingTitleData = {}
          if (typeof trainingTitleObj !== 'undefined') {
            trainingTitleData.training_title = trainingTitleObj.text_en
            trainingTitleData.training_title_bn = trainingTitleObj.text_bn
          } else {
            trainingTitleData.training_title = ''
            trainingTitleData.training_title_bn = ''
          }
          const OfficeTypeListObj = this.$store.state.CommonService.commonObj.officeTypeList.find(item1 => item1.value === parseInt(item.office_type_id))
          const OfficeTypeData = {}
          if (typeof OfficeTypeListObj !== 'undefined') {
            OfficeTypeData.office_type = OfficeTypeListObj.text_en
            OfficeTypeData.office_type_bn = OfficeTypeListObj.text_bn
          } else {
            OfficeTypeData.office_type = ''
            OfficeTypeData.office_type_bn = ''
          }
          const trainingTypeObj = this.$store.state.TrainingElearning.commonObj.trainingTypeList.find(doc => doc.value === parseInt(item.training_type_id))
          const trainingTypeData = {}
          if (typeof trainingTypeObj !== 'undefined') {
            trainingTypeData.training_type = trainingTypeObj.text_en
            trainingTypeData.training_type_bn = trainingTypeObj.text_bn
          } else {
            trainingTypeData.training_type = ''
            trainingTypeData.training_type_bn = ''
          }
          return Object.assign({}, { serial: index }, item, orgData, batchData, officeData, fiscalYearData, trainingTitleData, OfficeTypeData, trainingTypeData)
        })
        return listData
      },
      pdfExport () {
        this.$refs.details.pdfExport()
      },
      pdfExportList () {
        const reportTitle = this.$i18n.locale === 'en' ? 'Traning Schedule List' : 'প্রশিক্ষণের সময়সূচী তালিকা'
        ExportPdf.exportPdfDetails(trainingElearningServiceBaseUrl, '/config/report-head/detail', 12, reportTitle, this, this.allData)
      }
    }
}
</script>
