
<template v-slot:body>
  <b-overlay :show="loading">
  <b-row>
    <b-col>
      <list-report-head :base-url="trainingElearningServiceBaseUrl" uri="/config/report-head/detail" :org-id="formData.org_id">
        {{ $t('elearning_tim.attendance') + ' ' + $t('globalTrans.details') }}
      </list-report-head>
    </b-col>
  </b-row>
  <b-row>
    <b-col lg="12" sm="12">
      <b-table-simple class="tg mt-3" hover small caption-top>
        <tbody>
          <tr>
            <th style="width: 15%"  class="text-left" >{{ $t('elearning_iabm.circular_memo_no') }}</th>
            <th class="text-center" style="width: 5%">:</th>
            <td style="width: 30%" class="text-left">{{ formData.circular_memo_no }}</td>
            <th style="width: 15%" class="text-left" >{{ $t('elearning_config.fiscal_year') }}</th>
            <th class="text-center" style="width: 5%">:</th>
            <td style="width: 30%" class="text-left">{{ $i18n.locale === 'bn' ? formData.fiscal_year_bn : formData.fiscal_year }}</td>
          </tr>
          <tr>
            <th style="width: 15%"  class="text-left" v-if="$store.state.Auth.activeRoleId === 1 || $store.state.Auth.authUser.org_id === 1 || $store.state.Auth.authUser.org_id === 12">{{ $t('elearning_config.organization') }}</th>
            <th style="width: 5%" class="text-center" v-if="$store.state.Auth.activeRoleId === 1 || $store.state.Auth.authUser.org_id === 1 || $store.state.Auth.authUser.org_id === 12">:</th>
            <td style="width: 30%" class="text-left" v-if="$store.state.Auth.activeRoleId === 1 || $store.state.Auth.authUser.org_id === 1 || $store.state.Auth.authUser.org_id === 12">{{ $i18n.locale === 'bn' ? formData.org_bn : formData.org }}</td>
            <th style="width: 15%"  class="text-left">{{ $t('elearning_config.office_type') }}</th>
            <th class="text-center" style="width: 5%">:</th>
            <td style="width: 30%">{{ $i18n.locale === 'bn' ? formData.office_type_bn : formData.office_type }}</td>
          </tr>
          <tr>
            <th style="width: 15%"  class="text-left">{{ $t('globalTrans.office') }}</th>
            <th style="width: 5%" class="text-center">:</th>
            <td style="width: 30%">{{ $i18n.locale === 'bn' ? formData.office_bn : formData.office }}</td>
            <th style="width: 15%"  class="text-left">{{ $t('elearning_config.training_type') }}</th>
            <th style="width: 5%" class="text-center">:</th>
            <td style="width: 30%">{{ $i18n.locale === 'bn' ? formData.training_type_bn : formData.training_type }}</td>
          </tr>
          <tr>
            <th style="width: 15%"  class="text-left">{{ $t('elearning_config.training_category') }}</th>
            <th style="width: 5%" class="text-center">:</th>
            <td style="width: 30%">{{ $i18n.locale === 'bn' ? formData.training_category_bn : formData.training_category }}</td>
            <th style="width: 15%"  class="text-left">{{ $t('elearning_config.training_title') }}</th>
            <th style="width: 5%" class="text-center">:</th>
            <td style="width: 30%">{{ $i18n.locale === 'bn' ? formData.training_title_bn : formData.training_title }}</td>
          </tr>
        </tbody>
      </b-table-simple>
    </b-col>
  </b-row>
  <b-overlay :show="load">
    <b-row id="form">
      <div class="col-md-12">
        <fieldset class="p-2 w-100">
          <legend class="px-2 w-50 shadow-sm">{{ $t('elearning_tim.attendance') }} {{ $t('globalTrans.list') }}</legend>
          <table class="table" style="width:100%">
            <thead class="thead">
              <tr>
                <th class="text-center">{{$t('globalTrans.sl_no')}}</th>
                <th>{{$t('elearning_tim.training_date')}}</th>
                <th>{{$t('elearning_tim.trainee')}}</th>
                <th>{{$t('globalTrans.start_time')}}</th>
                <th>{{$t('globalTrans.end_time')}}</th>
              </tr>
            </thead>
            <tr v-for="(trainee, index) in trainees" :key="index">
              <b-td class="text-center">{{ $n(index + 1) }}</b-td>
                <td>
                {{ trainee.training_date | dateFormat }}
              </td>
              <td>
                {{ $i18n.locale === 'bn' ? trainee.name_bn : trainee.name }}
              </td>
              <td>
                {{ trainee.start_time | time12FormateTraining }}
                <!-- {{ $i18n.locale === 'bn' ? course.course_details_bn : course.course_details }} -->
              </td>
              <td>
                {{ trainee.end_time | time12FormateTraining }}
                <!-- {{ $i18n.locale === 'bn' ? course.trainer_name_bn : course.trainer_name }} -->
              </td>
            </tr>
          </table>
        </fieldset>
      </div>
    </b-row>
  </b-overlay>
  </b-overlay>
</template>
<script>

import flatpickr from 'flatpickr'
import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import ListReportHead from '@/components/custom/ListReportHeadTraining.vue'
import ExportPdf from './export_pdf_details'
import { attendanceShow } from '../../api/routes'
export default {
    components: {
        ListReportHead
    },
  props: ['id'],
  created () {
    if (this.id) {
      const tmp = this.getFormData()
      this.formData = tmp
      this.getCircularMemoNo(tmp.circular_memo_no)
    }
  },
  mounted () {
    flatpickr('.fromDate', {})
  },
  data () {
    return {
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      formData: {
        fiscal_year_id: 0,
        office_type_id: 0,
        office_id: 0
      },
      officeTypeList: [],
      trainees: [],
      officeList: [],
      roomlist: [],
      floorlist: [],
      roomRentList: [],
      trainingCategoryList: [],
      dateErrorMsg: '',
      load: false,
      trainingElearningServiceBaseUrl: trainingElearningServiceBaseUrl,
      trainingTitleList: []
    }
  },
  computed: {
    loading () {
      return this.$store.state.commonObj.loading
    },
    roomTypeList: function () {
      return this.$store.state.TrainingElearning.commonObj.roomTypeList.filter(item => item.status === 1)
    },
    orgList: function () {
      return this.$store.state.CommonService.commonObj.componentOrgList.filter(item => item.status === 1)
    },
    fiscalYearList: function () {
      return this.$store.state.CommonService.commonObj.fiscalYearList.filter(item => item.status === 1)
    },
    trainingTypeList: function () {
      return this.$store.state.TrainingElearning.commonObj.trainingTypeList.filter(item => item.status === 1)
    }
  },
  methods: {
    async getCircularMemoNo (getCircularMemoNo) {
      if (getCircularMemoNo) {
        this.load = true
        const result = await RestApi.getData(trainingElearningServiceBaseUrl, attendanceShow + '/' + getCircularMemoNo)
        if (!result.success) {
          this.trainees = []
        } else {
          if (result.data.length > 0) {
            this.trainees = result.data
          } else {
            this.trainees = []
          }
        }
        this.load = false
      }
    },
    getFormData () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    },
    getRoomRentList (typeId) {
      return this.$store.state.TrainingElearning.commonObj.roomRentList.filter(item => item.status === 1 && item.room_type_id === typeId)
    },
    getRoomList (typeId) {
      const roomlist = this.$store.state.TrainingElearning.commonObj.accomodationSetupList.filter(item => item.status === 1 && item.room_type_id === typeId)
      const tempData = roomlist.map(item => {
        if (this.$i18n.locale === 'bn') {
          return { text: item.room_no, value: item.room_no }
        } else {
          return { text: item.room_no, value: item.room_no }
        }
      })
      return tempData
    },
    getFloorList (typeId) {
      const floorlist = this.$store.state.TrainingElearning.commonObj.accomodationSetupList.filter(item => item.status === 1 && item.room_type_id === typeId)
      const tempData = floorlist.map(item => {
        if (this.$i18n.locale === 'bn') {
          return { text: item.floor_no, value: item.floor_no }
        } else {
          return { text: item.floor_no, value: item.floor_no }
        }
      })
      return tempData
    },
    getOfficeTypeList (orgId) {
      const OfficeTypeList = this.$store.state.CommonService.commonObj.officeTypeList.filter(item => item.status === 0)
      if (orgId) {
        return OfficeTypeList.filter(item => item.org_id === orgId)
      }
      return OfficeTypeList
    },
    getOfficeList (officeTypeId) {
      const officeList = this.$store.state.CommonService.commonObj.officeList.filter(item => item.status === 0)
      if (officeTypeId) {
        return officeList.filter(item => item.office_type_id === officeTypeId)
      }
      return officeList
    },
    getCategoryList (typeId) {
       const trainingCategoryList = this.$store.state.TrainingElearning.commonObj.trainingCategoryList.filter(item => item.status === 1)
       if (typeId) {
         return trainingCategoryList.filter(item => item.type_id === typeId)
       }
       return trainingCategoryList
    },
    pdfExport () {
      const reportTitle = this.$t('elearning_tim.attendance') + ' ' + this.$t('globalTrans.details')
      ExportPdf.exportPdfDetails(trainingElearningServiceBaseUrl, '/config/report-head/detail', this.formData.org_id, reportTitle, this.formData, this, this.trainees)
    },
    getTrainingTitleList (categoryId) {
      const trainingTitleList = this.$store.state.TrainingElearning.commonObj.trainingTitleList.filter(item => item.status === 1)
      if (categoryId) {
        return trainingTitleList.filter(item => item.category_id === categoryId)
      }
      return trainingTitleList
    }
  }
}
</script>
<style>
  .hidden_header {
    display: none
  }
  .card-border {
    border: 1px solid #b9bdc1;
    box-shadow: 1px 1px 6px -1px grey;
    background-color: #dee2e6;
  }
  [dir=ltr][mode=light] .card-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 10px;
  }
  .report-name{
    font-weight: bold !important;
    text-transform: uppercase;
  }
  .my-btn{
    padding: 2px !important;
  }
</style>
